.wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
    width: 100%;
}

.btn {
    width: 100%;
    height: 46px;
    border: 1px solid #e5e5e5;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font: 14px/24px 'SF-Pro-Display-Medium';
}
