.ConsumerKYC {
    background-color: #f9fcff;

    .ErrorText {
        margin-bottom: 0px;
        color: #da1e28 !important;
        font-size: 12px;
        line-height: 16px;
    }
}

.KYCContainer {
    margin: 20px 54px 20px 54px;
    min-height: 80vh;
    position: relative;

    @media screen and (max-width: 1024px) {
        margin: 20px;
        min-height: 100vh;
    }

    @media screen and (max-width: 500px) {
        margin: 12px;
    }
}

.KYCContent {
    position: relative;
    display: flex;
    gap: 20px;
    max-width: 85%;
    margin: 67px auto 0 auto;

    @media screen and (max-width: 1400px) {
        max-width: unset;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        margin: 20px auto 0 auto;
    }

    @media only screen and (max-width: 500px) {
        margin-top: 50px;
        gap: 16px;
    }
}

.KYCPageVideo {
    display: flex;
    align-items: center;
    margin-top: 16px;

    p {
        color: #32cc79;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 14px;
        line-height: 17.5px;
        margin-left: 5px;
    }

    &:hover {
        cursor: pointer;
    }
}

.LeftContainer {
    height: 100%;
    width: calc(32 / 100 * (85vw - 108px));
    position: fixed;
    z-index: 1;
    overflow: auto;

    @media screen and (max-width: 1400px) {
        width: calc(32 / 100 * (100vw - 108px));
    }

    @media screen and (max-width: 1240px) {
        width: 350px;
    }

    @media screen and (max-width: 1024px) {
        position: static;
        width: auto;
    }
}

.LeftContainerSection {
    width: 100%;
    padding-bottom: 20px;

    @media screen and (max-width: 1024px) {
        position: static;
        width: auto;
        max-width: none;
    }

    @media screen and (min-width: 768px) and (max-height: 900px) {
        position: relative;
        margin-bottom: 65px;
    }
}

.OnePageKYC_HeadingSection {
    .Heading {
        color: #233862;
        font-family: var(--font-bold);
        font-size: 18px;
        line-height: 24px;
    }

    .SubHeading {
        color: #4d4d4d;

        font-size: 14px;

        line-height: 21px;
    }
}

.SectionsContainer {
    width: 100%;
    margin-left: calc(32% + 20px);

    @media screen and (max-width: 1240px) {
        margin-left: 370px;
    }

    @media screen and (max-width: 1024px) {
        margin-left: 0;
    }

    @media only screen and (max-width: 500px) {
        padding-bottom: 100px;
    }
}

.SelectedSolutionContainer {
    margin-top: 24px;
    padding: 16px;
    border-radius: 14px;
    border: 1px solid #e2eeff;
    background: #fff;
    max-height: calc(100vh - 430px);
    overflow-y: auto;

    @media screen and (max-width: 1024px) {
        max-height: auto;
    }
}

.SelectedSolutionNav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h5 {
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.8);
        font-family: var(--font-medium);
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.78px;
        margin: 0;
    }

    .ASOTextIconBtn {
        border-radius: 30px;
        border: 0.5px solid #004aad;
        background: #f9fcff;
        align-items: center;

        &:hover {
            background-color: #004aad;
        }

        &.ASOTextIconBtn_Disabled {
            border: none;
            cursor: pointer;
            opacity: 70%;
            cursor: not-allowed;
        }

        @media screen and (max-width: 400px) {
            width: 100%;
            max-width: 130px;
        }
    }
}

.DetailsDropdownContainer {
    padding: 16px;
    margin: 24px 0;
    border-radius: 8px;
    border: 1px solid #e2eeff;
    background: #f9fcff;
}

.DetailsDropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.isOpen {
        padding: 0px 0px 8px 0px;
    }

    &:hover {
        cursor: pointer;
    }

    p {
        font-size: 14px;
        line-height: 21px;
    }
}

.Questions {
    color: #001837;
    font-size: 14px;
    line-height: 21px;

    span {
        color: #004aad;
        text-decoration: underline;
        font-size: 12px;
        font-family: var(--font-semibold);
        line-height: normal;
    }
}

.SectionContainer {
    background-color: #fff;
    width: 100%;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 14px;
    border: 1px solid #e2eeff;

    .HeaderContainer {
        display: flex;
        justify-content: space-between;
        background-color: white;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

    .Heading {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 0;
        width: 100%;

        svg {
            width: 14px;
            height: 14px;
            &:hover {
                cursor: pointer;
            }
        }

        @media screen and (max-width: 900px) {
            font-size: 12px;
            line-height: 18px;
        }
    }

    small {
        margin-top: 10.5px;
        color: #3d5075;
        font-size: 10px;
        line-height: 15px;

        span {
            color: #004aad;
            text-align: center;
            font-family: var(--font-semibold);
            font-size: 12px;
            line-height: normal;
            margin-left: 4px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .ProgressToggle {
        justify-content: flex-end;

        @media screen and (max-width: 400px) {
            column-gap: 10px;
            width: fit-content;
        }
    }

    .ProgressContainer {
        border-radius: 34px;
        border: 0.5px solid #e2eeff;
        background: #f9fcff;
        padding: 4px 8px;
        line-height: 1rem;

        .GroupItems {
            justify-content: center;
            gap: 2px;
            align-items: center;
        }

        .SectionProgressStatusIconBox {
            height: 14px;

            svg {
                width: 14px;
                height: 14px;
            }
        }

        span {
            border-radius: 24px;
            background: #e2eeff;
            width: 10px;
            height: 3px;
            margin-right: 2px;

            &:last-of-type {
                border-right: 0.5px solid #e2eeff;
                padding-right: 8px;
            }

            &.Active {
                background-color: #004aad;
            }

            @media screen and (max-width: 320px) {
                width: 6.5px;
            }
        }

        p {
            margin-left: 4px;
        }
    }

    @media only screen and (max-width: 500px) {
        padding: 16px 8px;

        .ProgressContainer {
            p {
                font-size: 14px;
            }
        }
    }
}

.isOpen {
    border-bottom: 0.8px solid #e2eeff;
    margin-bottom: 16px;
    padding: 16px 10px;
}

.GroupItems {
    display: flex;
    width: 100%;
    justify-content: space-between;
    column-gap: 16px;

    div:has(> div.InputFieldWrapper) {
        width: 100%;
    }

    div:has(> div.InputWrapper) {
        width: 100%;
    }

    &:has(> .InstallationText) {
        margin: 13px 0px 19px 0px;
    }

    .SelectNoFloatingLabelWrapper,
    .SelectFloatingLabelWrapper,
    .SelectBox {
        width: 100%;
    }

    .SelectNoFloatingLabelWrapper,
    .SelectFloatingLabelWrapper {
        max-width: 50%;

        @media screen and (max-width: 768px) {
            max-width: none;
        }
    }

    &.AddressDetails,
    &.BusinessDetails {
        @media screen and (max-width: 500px) {
            flex-direction: column;
            gap: 16px;

            > div {
                margin-bottom: 0 !important;
            }
        }
    }

    &.ContainsBadges {
        column-gap: 8px !important;
        margin-top: 8px !important;
    }
}

.Block {
    @media screen and (max-width: 500px) {
        display: block !important;
    }
}

.InstallationText {
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    color: #233862;

    @media screen and (max-width: 600px) {
        font-size: 12px;
        line-height: 18px;
    }
}

.DetailsContainer {
    p {
        color: #233862;
        font-size: 14px;
        line-height: 21px;

        @media screen and (max-width: 600px) {
            font-size: 12px;
            line-height: 18px;
        }
    }
    .GroupItems {
        margin-top: 8px;
        column-gap: 20px;
        justify-content: flex-start;
    }

    .BusinessDetails {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 18px;
        row-gap: 4px;

        .InputFieldWrapper,
        .field_container {
            width: 100%;
            max-width: 100%;
        }

        @media screen and (max-width: 500px) {
            margin-top: 12px;
            grid-template-columns: repeat(1, 1fr);
            row-gap: 12px;

            div {
                margin-bottom: 0px !important;
            }

            & > div {
                margin-top: 0px !important;
            }
        }
    }

    .isBusiness {
        margin-top: 16px;

        .GroupItems {
            margin-top: 16px;
        }
    }
}

.DetailsContainer,
.ImageContainer {
    .ErrorText {
        margin-left: 0;
    }
}
.noImage {
    button {
        background: #fafafa;
        border: 1px solid #fafafa;
        display: flex;
        align-items: center;
        cursor: not-allowed;
        height: 62px;
        gap: 8px;
        padding: 0px 10px;
        border-radius: 10px;
        span {
            background-color: #fff;
            height: 46px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            border: 1px solid #f3f3f3;
        }
        p {
            color: #b0b0b0;
        }
    }
}

.AddImage {
    > button {
        display: flex;
        padding: 8px;
        align-items: center;
        justify-content: center;
        gap: 16px;
        border-radius: 10px;
        border: 0.5px dashed var(--disabled-btn, rgba(0, 74, 173, 0.79));
        background: var(--accent-blue-light, #e2eeff);
        cursor: pointer;

        > p {
            color: var(--accent-blue-primary, #004aad);
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            line-height: 21px;
        }

        img {
            width: 25.5px;
            height: 25.5px;
        }

        span {
            display: block;
            border-radius: 10px;
            border: 0.5px solid #004aadc9;
            background: #fff;
            padding: 10px 12px;
        }

        @media screen and (max-width: 600px) {
            width: 100%;
            justify-content: left;
        }
    }
    button:disabled {
        cursor: not-allowed;
    }
}

.ThirdParty {
    max-width: 450px;
    padding: 14px 16px;
    border-radius: 14px;
    background: #f9fcff;

    button {
        margin: 8px 0;
        border-radius: 8px;
        border: 0.5px solid #004aad;
        font-size: 14px;
        line-height: 24px;
        padding: 7px 9px;
        font-family: var(--font-semibold);
        background-color: transparent;

        img {
            margin-right: 7px;
            height: 29px;
        }
    }

    p {
        color: #011a3c;
    }

    p:first-of-type {
        color: #011a3c;
        font-size: 14px;
        font-family: var(--font-light);

        span {
            font-family: var(--font-regular);
            line-height: 21px;
        }
    }

    .note {
        font-size: 12px;
        line-height: 18px;
    }

    @media only screen and (max-width: 500px) {
        background-color: #e2eeff;
    }
}

.ManualUpload {
    display: flex;
    justify-content: flex-end;
}

.TopArrowOpen {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.TopArrowClosed {
    transition: transform 0.3s ease;
}

.Types {
    margin-bottom: 16px;
    gap: 8px !important;
    flex-wrap: wrap;
}

.Badge {
    border-radius: 33px;
    border: 0.5px solid #c4c4c4;
    background: #fff;
    padding: 12px;
    color: #011a3ce0;
    font-size: 14px;
    line-height: 21px;
    min-width: 70px;
    text-align: center;

    &:hover {
        cursor: pointer;
    }
    @media screen and (max-width: 1400px) {
        min-width: 60px;
    }

    @media screen and (max-width: 600px) {
        font-size: 14px;
        line-height: 21px;
        padding: 8px 12px;
    }
}

.ActiveType {
    background-color: #e2eeff !important;
    color: rgba(1, 26, 60, 0.88);
    font-family: var(--font-regular);
    border: 0.5px solid #004aad;
}

.SwitchWrapper {
    display: flex;
    align-items: center;
    gap: 4px;

    span {
        color: #c4c4c4;
        text-align: right;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        font-style: normal;
        line-height: 27px;

        @media screen and (max-width: 900px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    button {
        min-width: 35px !important;
    }
}

.FinancialKYC,
.PersonalInfo,
.DirectorInfo {
    .GroupItems {
        @media screen and (max-width: 600px) {
            flex-direction: column;
        }
    }
}

.EnergyInfo {
    .EnergyInfoDetails {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        margin-top: 16px;

        div {
            margin: 8px 0;
        }

        div:nth-of-type(even) {
            text-align: right;
        }
    }

    .GroupItems {
        margin-top: 8px;
    }

    h6 {
        color: #3d5075;
        font-size: 12px;
        font-family: var(--font-light);
        line-height: 18px;
        margin-bottom: 0;
    }

    p {
        color: #233862;
        font-size: 14px;
        line-height: 26px;
    }

    .EditInfo {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        padding: 8px 12px;
        border-radius: 30px;
        border: 0.5px solid #004aad;
        background: #f9fcff;
        height: fit-content;

        p {
            font: 12px 'SF-Pro-Display-Medium';
            color: #004aad;
            line-height: normal;
        }

        svg {
            stroke: #004aad;
        }

        &:hover {
            background: #004aad;
            border-radius: 30px;

            p {
                color: #ffffff;
            }

            svg {
                stroke: #fff;
            }
        }
    }
}

.ChangeUseType {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    p {
        color: #001837;
        font-size: 14px;
        line-height: 24px;
    }

    button {
        padding: 0px 12px !important;
        width: 145px;
    }

    @media only screen and (max-width: 500px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 7px;
    }
}

.imageContainer {
    max-width: 189px;
    width: 100%;
    border-radius: 14px;
    background: #011a3c;
    padding: 7px;
    height: 68px;
    position: relative;

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    .imageIcon {
        border-radius: 100%;
        background: #32cc79;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 10px;
        bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 15px;
            height: 15px;
            rect {
                fill: transparent;
            }
        }
    }

    @media screen and (max-width: 600px) {
        max-width: unset;
    }
}

.flex-column {
    @media screen and (max-width: 700px) {
        flex-direction: column;
        gap: 16px;

        > div {
            margin-bottom: 0 !important;
        }
    }
}

.OPKYC_HeadingHelpIcon {
    margin-top: 5px;
}
.imageContainer {
    max-width: 189px;
    width: 100%;
    border-radius: 14px;
    background: #011a3c;
    padding: 7px;
    height: 68px;
    position: relative;

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    .imageIcon {
        border-radius: 100%;
        background: #32cc79;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 10px;
        bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 15px;
            height: 15px;
            rect {
                fill: transparent;
            }
        }
    }

    @media screen and (max-width: 600px) {
        max-width: unset;
    }
}

.flex-column {
    @media screen and (max-width: 700px) {
        flex-direction: column;
        gap: 10px;
    }
}

.imageContainer {
    max-width: 189px;
    width: 100%;
    border-radius: 14px;
    background: #011a3c;
    padding: 7px;
    height: 68px;
    position: relative;

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    .imageIcon {
        border-radius: 100%;
        background: #32cc79;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 10px;
        bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 15px;
            height: 15px;
            rect {
                fill: transparent;
            }
        }
    }

    @media screen and (max-width: 600px) {
        max-width: unset;
    }
}

.CalenderBox,
.react-datepicker-wrapper,
.DynamicInfoDateFieldWrapper {
    width: 100%;
}

.active_fields {
    background-color: #004aad !important;
}

.error_fields {
    background-color: #da1e28 !important;
}

.OnePageKYC_ProgressLoader {
    width: 48px;
    height: 26px;
    border-radius: 34px;
    border: 0.5px solid #e2eeff;
    background-color: #f9fcff;
    display: grid;
    place-items: center;
}
